// Here you can add other styles

span, li, div , a{
    &:hover, &:focus {
        text-decoration: none !important;
    }
    text-decoration: none !important;
}

.sideBarItem {

    & .c-sidebar-nav-link {
        &:hover {
            background-color: #9e183d;
        }
    }

    &.bottom {
        position: absolute;
        bottom: 0px;
        width: 100%;
    }
}

.axesFilter, .dimensionsFilter {
    width: 30%;
    margin-left:10px;
    display: inline-block;
}

.axisRemove, .dimensionRemove {
    padding: 6px;
    position: absolute;
    right: 15px;
    bottom: 6px;
    border-radius: 5px;
    background-color: white;
    text-decoration: none;
    border: 1px solid darkgrey;
    cursor: pointer;
    line-height: 1px;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

.dropdown-selector {
    position:absolute;
    top:35px;
    background-color: white;
    padding-left:130px;
    width: 100%;
    z-index:1;
    cursor: pointer;
    .list-group-item {
        &:hover {
            background-color: rgb(161, 157, 157)
        }
    }
    overflow: scroll;
    max-height: 200px;
}

.autoCompleteContainer {
    &  .css-2b097c-container {
        width: 30%;
        & input {
            font-size:16px !important;
            color: black !important;
        }
    }

}

/* Set default font-family */
.app .ql-editor {
    font-family: "Roboto";
}

/* Set dropdown font-families */
.ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before
{
    font-family: "Roboto", cursive;
    content: "Roboto" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before
{
    font-family: "Raleway", cursive;
    content: "Raleway" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Alegreya-Sans"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Alegreya-Sans"]::before
{
    font-family: "Alegreya Sans", cursive;
    content: "Alegreya Sans" !important;
}

/* Set content font-families */
.ql-font-Raleway {
    font-family: "Raleway";
}
.ql-font-Alegreya-Sans {
    font-family: "Alegreya Sans";
}
